import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/data-migration/wifi.png"),
    altText: "Home",
    caption: "Home",
  },
  {
    src: require("assets/data-migration/search_device.png"),
    altText: "search_device.png",
    caption: "search_device.png",
  },
  {
    src: require("assets/data-migration/select_device.png"),
    altText: "select_device.png",
    caption: "select_device.png",
  },
  {
    src: require("assets/data-migration/confirm_pin.png"),
    altText: "confirm_pin.png",
    caption: "confirm_pin.png",
  },

  {
    src: require("assets/data-migration/confirm_pin.png"),
    altText: "transfer_type.png",
    caption: "transfer_type.png",
  },

  {
    src: require("assets/data-migration/confirm_pin.png"),
    altText: "select_item.png",
    caption: "select_item.png",
  },
  {
    src: require("assets/data-migration/confirm_pin.png"),
    altText: "transfer_progress.png",
    caption: "transfer_progress.png",
  },
  {
    src: require("assets/data-migration/transfer_complete.png"),
    altText: "transfer_complete.png",
    caption: "transfer_complete.png",
  },

  {
    src: require("assets/data-migration/cloud.png"),
    altText: "cloud.png",
    caption: "cloud.png",
  },

  {
    src: require("assets/data-migration/utilities.png"),
    altText: "utilities.png",
    caption: "utilities.png",
  },
];

function DataMigrationSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" id="carousel">
        <Container>
          <div className="title">
            <h1>Data Copy</h1>
          </div>
          <div>
            <h4>Seamless Data Transfer Made Simple</h4>
          </div>
          <div>
            <p>Easily transfer your important data between devices with Data Copy, the ultimate tool for secure and efficient data management. Whether you're switching phones or organizing your content, Data Copy makes the process smooth and hassle-free.

Key Features
Direct Peer-to-Peer Data Transfer
Transfer your data quickly and securely between devices without the need for an internet connection.

No Wi-Fi or connection Required
Experience true convenience with direct device-to-device data sharing.

Transfer What Matters Most
Seamlessly move your Contacts, Calendars, Photos, and Videos to your new device.

Backup and Restore via Cloud
Safeguard your data with easy backup and restore options from popular cloud services.

Smart Utilities for Cleanup
Organize your device with tools to identify and clear duplicate Contacts, Calendars, Photos, and Videos.

Why Choose Data Copy?
Simple and intuitive interface for all users.
Fast, reliable, and secure data transfers.
Save time with efficient cleanup tools.
Download Data Copy today and take control of your data like never before!

</p>
          </div>
          <Row className="justify-content-center">
            <Col lg="4" md="6">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DataMigrationSection;
